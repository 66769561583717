@media screen and (max-width: 767px) {
    .project-card{
        list-style: none;
        width: 90%;
        max-width: 300px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 12px 8px 12px 8px;
        padding: 16px 24px 16px 24px;
        background: rgba( 255, 255, 255, 0.15 );
        box-shadow: 0 8px 14px 0 rgba( 31, 38, 135, 0.37 );
        backdrop-filter: blur( 4px );
        -webkit-backdrop-filter: blur( 4px );
        border-radius: 10px;
        animation: fadeIn 1.5s forwards;
        opacity: 0;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    .project-title-container{
        margin-bottom: 12px;
    }

    .project-title{
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',Helvetica, Arial, sans-serif;
        font-size: 22px;
        margin: 0px;
    }

    .project-description{
      font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',Helvetica, Arial, sans-serif;
        font-size: 16px;
        margin: 12px 0px 0px 0px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .project-concepts, .project-skills{
      font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',Helvetica, Arial, sans-serif;
      font-size: 16px;
      font-weight: bold;
      margin: 12px 0px 12px 0px;
    }

    .projects-button-container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }

    .project-details-container{
      margin: 0px 0px 8px 0px;
    }

    .project-link{
        text-decoration: none;
    }

    .project-button{
        height: 32px;
        min-width: 102px;
        max-width: 112px;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-around;
        align-items: center;
        background: #4CAF50;
        color: snow;
        cursor: pointer;
        animation: glow 1s ease-in infinite;
        border: none;
        border-radius: 2px;
    }

    .code{
        background: #2586cc;
        color: snow;
        cursor: pointer;
        animation: glow-code 1s ease-in infinite;
        border: none;
        border-radius: 2px;
    }

    @keyframes glow {
        0% {
          background: #4CAF50;
        }
        25% {
          background: #66BB6A;
        }
        100% {
          background: #4CAF50;
        }
      }

      @keyframes glow-code {
        0% {
          background: #2586cc;
        }
        25% {
          background: #3c96d6;
        }
        100% {
          background: #2586cc;
        }
      }

    .project-button-tag{
        margin: 0px 2px 0px 0px;
        letter-spacing: 0px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',Helvetica, Arial, sans-serif;
        font-weight: 500;
        font-size: 15px;
        color: snow;
    }

    .project-icon{
        font-size: 18px;
        color: snow;
        font-weight: bolder;

    }
}
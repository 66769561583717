@media screen and (max-width: 767px) {
    .nav-bar-small{
        height: 45px;
        padding-left: 16px;
        padding-right: 16px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #CCCCCC;
        background-color: #ffffff;
        position: sticky;
        top: 0;
        width: 100%;
        z-index: 9999;
    }
    .nav-bar-sub-one{
        display: flex;
        flex-direction: row;
    }
    .profile-name-link{
        text-decoration: none;
    }

    .nav-bar-profile-name{
        margin: 0px;
        font-size: 18px;
        font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
        margin-left: 4px;
        margin-right: 4px;
        color: #000000;
    }   

    .nav-bar-icon{
        font-size: 22px;
    }

    .nav-bar-menu-button{
        height: 40px;
        width: 40px;
        background-color: transparent;
        border: none;
        padding: 8px;
    }
} 

@media screen and (min-width: 767px) and (max-width: 1263px) {
}
@media screen and (max-width: 767px) {
    .route-navbar-button{
        border: none;
        background-color: transparent;
        flex-grow: 1;
        padding: 6px;
        border-bottom: 1px solid #737373;
    }

    .route-div{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        
    }

    .route-navbar-icon{
        font-size: 28px;
        color: #8d8d8d;
    }

    .active-tab{
        border-bottom: 1px solid #fa0505;
    }

    .active-icon{
        color: #fa0505;
        font-weight: bolder;
    }
}
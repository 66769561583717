@media screen and (max-width: 767px) {
    .skills-section{
        margin: 0px 16px 24px 16px;
        display: flex;
        flex-direction: row;
        overflow-x:scroll;
        background-color: #ffffff;
        
    }
    .each-skill-card{
        height: 95px;
        padding-left: 4px;
        padding-right: 4px;
        margin-right: 4px;
        margin-left: 4px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .skill-icon-link{
        text-decoration: none;
        background-color: #efefef;
        color: #262626;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 65px;
        width: 65px;
        border: 1.5px dashed #262626;
        border-radius: 50px;
        animation: spin 2s linear forwards;
    }

    @keyframes spin { 
        100% { 
          transform: rotateZ(360deg);
        }
      }
    .skill-icon{
        font-size: 45px;
        position: static;
    }
    .skill-icon-desc{
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',Helvetica, Arial, sans-serif;
        font-weight: bold;
        margin: 1px;
    }
}
@media screen and (max-width: 767px) {
    .routes-navbar{
        margin: 0px 0px 24px 0px;
        height: 44px;
        display: flex;
        flex-direction: row;
        padding: 0px;
        position: -webkit-sticky;
        position: sticky;
        top: 44px;
        z-index: 9999;
        background-color: #ffffff;
    }
}
@import url(https://fonts.googleapis.com/css?family=Exo:100);

@-webkit-keyframes bg-scrolling-reverse {
  100% { background-position: 50px 50px; }
}
@-moz-keyframes    bg-scrolling-reverse {
  100% { background-position: 50px 50px; }
}
@-o-keyframes      bg-scrolling-reverse {
  100% { background-position: 50px 50px; }
}
@keyframes         bg-scrolling-reverse {
  100% { background-position: 50px 50px; }
}

@-webkit-keyframes bg-scrolling {
  0% { background-position: 50px 50px; }
}
@-moz-keyframes    bg-scrolling {
  0% { background-position: 50px 50px; }
}
@-o-keyframes      bg-scrolling {
  0% { background-position: 50px 50px; }
}
@keyframes         bg-scrolling {
  0% { background-position: 50px 50px; }
}

/* Main styles */
.home-route {
  height: 90vh;
  color: #999;
  font: 400 16px/1.5 exo, ubuntu, "segoe ui", helvetica, arial, sans-serif;
  text-align: center;
  display: flex;
  flex-direction: column;
    justify-content: center;
    align-items: center;
  /* img size is 50x50 */
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAIAAACRXR/mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAABnSURBVHja7M5RDYAwDEXRDgmvEocnlrQS2SwUFST9uEfBGWs9c97nbGtDcquqiKhOImLs/UpuzVzWEi1atGjRokWLFi1atGjRokWLFi1atGjRokWLFi1af7Ukz8xWp8z8AAAA//8DAJ4LoEAAlL1nAAAAAElFTkSuQmCC") repeat 0 0;
  
  -webkit-animation: bg-scrolling-reverse .92s infinite; /* Safari 4+ */
  -moz-animation:    bg-scrolling-reverse .92s infinite; /* Fx 5+ */
  -o-animation:      bg-scrolling-reverse .92s infinite; /* Opera 12+ */
  animation:         bg-scrolling-reverse .92s infinite; /* IE 10+ */
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function:    linear;
  -o-animation-timing-function:      linear;
  animation-timing-function:         linear;
}

.home-route-content{
    margin: 0px;
    padding: 12px 16px 12px 16px;
    text-align: left;
    overflow-y: scroll;
}